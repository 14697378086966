//$monero-orange: #ff6600; // orange
$brand-color: #00c0cc; // baby-blue
// $brand-color: #4c4c4c; // grey
// $brand-color: #ff6600; // orange
// $brand-color: #4182e4;
// $secondary-brand-color: #4182e4;
// $secondary-brand-color: #ff6600; // orange
// $secondary-brand-color: #00c0c7;
$secondary-brand-color: #4c4c4c; // grey
$middle-gradient-color: mix($brand-color, $secondary-brand-color, 95%);

// Breakpoints
$tablet: "(min-width: 450px)";
$mid-point: "(min-width: 620px)";
$desktop: "(min-width: 768px)";
